<template>
    <div id="avaliacaoDosDocumentos">
        <div>
            <b-card class="card">

                <h3 style="color: brown">Controle de Documentações</h3>

                <br>

                <div>
                    <b-jumbotron style="background-color: #F9F9F9">

                        <b-row style="margin-top: -25px">

                            <div class="col-lg-4">
                                <h3>Dados Pessoais</h3>
                            </div>

                            <div class="col-lg-4 centralizado">
                                <a :href="cooperado.url_foto_perfil" target="blank">
                                    <b-avatar :src="cooperado.url_foto_perfil" size="100"></b-avatar>
                                </a>
                            </div>

                            <div class="col-lg-4">
                                <b-button 
                                variant="warning" 
                                class="botao-voltar"
                                @click="toDocsPendentes"
                                >Voltar
                                </b-button>
                            </div>

                        </b-row>

                        <br>
                        <br>

                        <b-row>

                            <div class="col-lg-6 form-group">
                                <label><span class="text-danger">*</span> Nome</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="cooperado.nome" 
                                ></b-form-input>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label><span class="text-danger">*</span> Situação</label>
                                <b-form-input 
                                readonly
                                v-model="cooperado.status"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> Situação na cooperativa</label>
                                <b-form-input 
                                readonly
                                v-model="cooperado.cooperativa"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> Matricula</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="cooperado.matricula"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> CPF</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="cooperado.cpf"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> Data Nascimento</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="cooperado.data_nascimento"
                                ></b-form-input>
                            </div>

                        </b-row>

                        <div id="foto-perfil" v-if="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'fotoPerfil').length > 0">

                            <hr style="background-color: red">

                            <h3>Foto de Perfil</h3>

                            <br>

                            <b-row align-h="center">

                                <div class="col-lg-3 form-group">
                                    <a :href="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'fotoPerfil')[0].urlDocumento" target="blank">
                                        <b-img thumbnail
                                            :src="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'fotoPerfil')[0].urlDocumento" 
                                        > 
                                        </b-img>
                                    </a>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                                <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="fotoDePerfil.avaliacao" 
                                    name="fotoPerfil" 
                                    value="Aprovado"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="fotoDePerfil.avaliacao" 
                                    name="fotoPerfil" 
                                    value="Reprovado"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="fotoDePerfil.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="fotoDePerfil.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="fotoDePerfil.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeFotoPerfil" 
                                        v-model="fotoDePerfil.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>
                        </div>


                        <div id="atestado" v-if="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'atestadoMedico').length > 0">

                            <hr style="background-color: red">

                            <h3>Atestado</h3>

                            <br>

                            <b-row align-h="center">

                                <div class="col-lg-3 form-group">
                                    <a :href="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'atestadoMedico')[0].urlDocumento" target="blank">
                                        <b-img thumbnail
                                            :src="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'atestadoMedico')[0].urlDocumento" 
                                        ></b-img>
                                    </a>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                                <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="atestadoMedico.avaliacao" 
                                    name="atestadoMedico" 
                                    value="Aprovado"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="atestadoMedico.avaliacao" 
                                    name="atestadoMedico" 
                                    value="Reprovado"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoMedico.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="atestadoMedico.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoMedico.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeAtestado" 
                                        v-model="atestadoMedico.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>
                        </div>


                        <div id="antecedentes-criminais" v-if="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'atestadoAntecedentes').length > 0">

                            <hr style="background-color: red">

                            <h3>Antecedentes Criminais</h3>

                            <br>

                            <b-row align-h="center">

                                <div class="col-lg-3 form-group">
                                    <a :href="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'atestadoAntecedentes')[0].urlDocumento" target="blank">
                                        <b-img thumbnail
                                        :src="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'atestadoAntecedentes')[0].urlDocumento" 
                                        ></b-img>
                                    </a>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                                <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="atestadoAntecedentes.avaliacao" 
                                    name="atestadoAntecedentes" 
                                    value="Aprovado"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="atestadoAntecedentes.avaliacao" 
                                    name="atestadoAntecedentes" 
                                    value="Reprovado"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoAntecedentes.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="atestadoAntecedentes.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoAntecedentes.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeAntecedentes" 
                                        v-model="atestadoAntecedentes.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>

                        </div>


                        <div id="uniforme" v-if="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'uniforme').length > 0">

                            <hr style="background-color: red">

                            <br>

                            <h3>Uniforme</h3>

                            <br>

                            <b-row align-h="center">

                                <div class="col-lg-3 form-group">
                                    <a :href="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'uniforme')[0].urlDocumento" target="blank">
                                        <b-img thumbnail
                                            :src="cooperado.docsParaAvaliacao.filter(item => item.tipo == 'uniforme')[0].urlDocumento" 
                                        ></b-img>
                                    </a>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                            <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="uniforme.avaliacao" 
                                    name="uniforme" 
                                    value="Aprovado"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="uniforme.avaliacao" 
                                    name="uniforme" 
                                    value="Reprovado"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="uniforme.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="uniforme.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="uniforme.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeUniforme" 
                                        v-model="uniforme.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>

                        </div>

                        <hr style="background-color: red">

                        <br>

                        <b-row class="botoes-centralizados">

                            <div class="col-lg-2 form-group" v-if="showBotaoConfirmar">
                                <b-button variant="success" size="lg" @click="confirmar">
                                    <b-icon-check2></b-icon-check2>
                                    Confirmar
                                </b-button>
                            </div>

                            <div class="text-center" v-else>
                                <b-spinner variant="warning" label="Spinning"></b-spinner>
                                <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
                                <!-- <b-spinner variant="danger" label="Spinning"></b-spinner>
                                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner> -->
                                <b-spinner variant="info" label="Spinning"></b-spinner>
                                <b-spinner variant="info" type="grow" label="Spinning"></b-spinner>
                                <b-spinner variant="success" label="Spinning"></b-spinner>
                                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
                            </div>

                        </b-row>

                    </b-jumbotron>
                </div>

            </b-card>
        </div>
    </div>
</template>c

<script>

import api from "../../services/api";

export default {

  name: 'AvaliacaoDocs',
  props: {
    cooperado: {}
  },

  data() {
    return {

        showBotaoConfirmar: true,

        fotoDePerfil: { 
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null
        },
        atestadoMedico: { 
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null 
        },
        atestadoAntecedentes: {
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null 
        },
        uniforme: { 
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null 
        },

        motivos: [
            'A foto não está nítida',
            'O documento está danificado',
            'O documento está fora de validade',
            'O documento diverge das credenciais do sistema'
        ]

    };
  },

  created() {
    // this.getCooperado()
    // console.log(this.cooperado)
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    toDocsPendentes() {
        this.$router.push('/coopDocsPendentes')
    },

    getInfosDocumentosModel(tipo) {

        if (tipo == 'fotoPerfil') return this.fotoDePerfil
        if (tipo == 'atestadoMedico') return this.atestadoMedico
        if (tipo == 'atestadoAntecedentes') return this.atestadoAntecedentes
        if (tipo == 'uniforme') return this.uniforme
    },

    confirmar() {

        this.showBotaoConfirmar = false

        const documentosAvaliados = []
        for (let documento of this.cooperado.docsParaAvaliacao) {

            const documentoModal = this.getInfosDocumentosModel(documento.tipo)

            const docFinal = {
                id: documento.id,
                cooperativa: documento.cooperativa,
                matricula: documento.matricula,
                tipo: documento.tipo,
                urlDocumento: documento.urlDocumento,
                avaliacao: documentoModal.avaliacao, 
                dataVencimento: documentoModal.dataValidade,
                motivoReprovacao: documentoModal.motivoReprovacao
            }

            documentosAvaliados.push(docFinal)
        }


        api.put(`webadmin/coopDocsAvaliacao`, documentosAvaliados)
        .then(() => {
            this.$router.push('/coopDocsPendentes')
        })
        .catch(err => {

            this.showBotaoConfirmar = true
            const res = err.response.data.split('|')

            res.length > 1
            ? this.msg(res[0], res[1], 'danger')
            : this.msg('ATENÇÃO!', err.response.data, 'danger')
        })

    }

  }

}

</script>

<style scoped>

    .card{
        margin: 40px;
    }

    .centralizado{
       text-align: center;
    }

    .botao-voltar{
        float: right;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .checkbox-e-select {
        margin-top: 500px;
    }

    .botoes-centralizados {
        justify-content: center;
        align-items: center;
    }

    .avaliacao-documento {
        justify-content: right;
    }

</style>