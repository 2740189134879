<template>
  <div id="docsPendentes">
      
    <div>
        <b-card
        class="card-documentacao"
        >

        <h3 class="titulo">Controle de Documentações</h3>
        
        <div>
            <b-table
            id="table-docsPendentes"
            striped 
            responsive
            hover 
            :items="cooperados"
            :fields="fields"
            :busy="load"
            outlined
            >

            <template v-slot:cell(cooperado)="data">       
              <b-button variant="outline-info btn-sm" @click="toCooperadoDetalhes(data.item)">Analisar</b-button>
            </template>

            </b-table>
        </div>

        </b-card>
    </div>

  </div>
</template>

<script>

import api from "../../services/api";

export default {

  name: 'DocsPendentes',
  data() {
      return{

        fields: [
          {key: 'matricula', label: 'Matrícula', formatter: 'matricula'},
          {key: 'nome', label: 'Nome', formatter: 'nome'},
          {key: 'dataEnvio', label: 'Atualizado em', formatter: 'atualizado'},
          {key: 'cooperado', label: 'Documentação', formatter: 'documentacao'},
        ], 
        
        cooperados: []

      };
  },

  created() {

    this.getCooperadosDocsPendentes()

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    getCooperadosDocsPendentes() {

      api.get(`webadmin/coopDocsPendentes`)
      .then(res => {

          // console.log(res.data)
          this.cooperados = res.data
          this.verificarCooperados()
      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))    
    },

    verificarCooperados() {
      if (this.cooperados.length == 0) this.$router.push('dashboard')  
    },

    toCooperadoDetalhes(cooperado) {
      this.$router.push({ name: 'AvaliacaoDosDocumentos', params: { cooperado: cooperado }})
    }

  }
}
</script>

<style scoped>
  .card-documentacao {
    margin: 40px;
  }

  .titulo {
    color: brown;
  }
</style>